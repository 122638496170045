import React from "react"
import { graphql, Link  } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "../components/Elements/Img"
import SectionContainer from "../components/SectionContainer"
import VideoPlayer from "../components/VideoPlayer/index"
import { Container, Row, Col } from "../components/Grid"
import {
  SectionBody,
  AccentHeader,
  PageTitle,
} from "../components/Elements/index"

const WorkLink = styled(props => <Link {...props} />)`
  color: #414141;
  &:active {
    color: #414141;
  }

  &:visited {
    color: #414141;
  }
`


const Footer = styled.footer`
  background: #6a115a;
  display: flex;
  height: 120px;
  align-items: center;
`

const RevomeMarginImage = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const RevomeMarginVideo = styled.div`
  .gatsby-image-wrapper {
    margin: 0;
  }
`

const VideoContainerFrame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    margin: 0;
    width: 100%;
  }
  iframe {
    position: absolute;
    z-index: 999;
    bottom: 3%;
    width: 53% !important;
    height: 100% !important;
    margin-left: -1%;
  }
`

const pluvictoConsumerPrint = ({ data }) => (
  <Layout>
    <ThemeProvider
      theme={{
        backgroundColor: "#FFFFFF",
        primaryColor: "#414141",
        dividerColor: "#1D4289;",
        invert: {
          backgroundColor: "#1D4289",
          dividerColor: "#ffffff;",
        },
      }}
    >
      <>
        <Seo title="PLUVICTO | PLUVICTO Consumer Print" />
        <SectionContainer noBottomMargin>
          <Container>
            <AccentHeader size="large" background="#ffffff">
              <WorkLink to="/">
                 <div style={{ color: "#1D4289"}}>
                    The Work
                  </div>
                </WorkLink>
            </AccentHeader>
            <div style={{ paddingBottom: "30px" }}>
            <PageTitle>
              <div style={{ color: "#1D4289"}}>
                A targeted approach to patient education
              </div>
            </PageTitle>
            </div>
            <Row>
              <Col md={4}>                
                <AccentHeader>
                  <div style={{ color: "#1D4289"}}>
                    The Problem
                  </div>
                </AccentHeader>                
                <SectionBody size="regular">
                  For patients with mCRPC, treatment options have been historically
limited, and many are ineligible for targeted therapies. The approval of
PLUVICTO changed all that. PLUVICTO targets PSMA, a biomarker
found in more than 80% of patients with prostate cancer, which is
exciting news. The challenge was how to translate a very complex,
scientific story into helpful, understandable information for patients
and caregivers.  <br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1 style={{ color: "#1D4289"}}>Bringing simplicity to high-level science</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>
        

        <SectionContainer invert noBottomMargin noTopMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "30px", color: "#ffffff" }}>
            <Row>
              <Col md={4}>                
                <AccentHeader>The Idea</AccentHeader>                
                <SectionBody size="regular">
                  What makes PLUVICTO different from other metastatic prostate cancer
treatments is that it delivers targeted radiation directly to PSMA+ cells
wherever they are found in the body. To make a very daunting scientific
concept more approachable, we had to find a way to make it visually
simple—but with a twist. We took a basic dart game and altered it so
one dart divides into many to hit all the targets at once. <br />
                  <br />                  
                </SectionBody>
              </Col>
              <Col md={4}>
                <AccentHeader></AccentHeader>
                <SectionBody size="regular">
                  <h1>One target. Many locations. One treatment.</h1>
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image2.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>

        <SectionContainer invert noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "30px", color: "#ffffff" }}>
            <Row>
              <Col md={8}>                
                <AccentHeader>The Execution</AccentHeader>                
                <SectionBody size="regular">
                  The PLUVICTO patient print campaign maintains the hallmark visuals, design elements, and patient-friendly language used in the digital
campaign to create a seamless experience across all touch points, including the physician's office. The patient brochure was also made
available as a download on the PLUVICTO patient website to ensure convenient access and harmonize both campaigns. <br />
                  <br />                  
                </SectionBody>
              </Col>
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <RevomeMarginImage>
            <Img fluid={data.image3.childImageSharp.fluid} />
          </RevomeMarginImage>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={4}>                
                <RevomeMarginImage>
                  <Img fluid={data.image4.childImageSharp.fluid} />
                </RevomeMarginImage>
              </Col> 
              <Col md={4}>                
                <RevomeMarginImage>
                  <Img fluid={data.image5.childImageSharp.fluid} />
                </RevomeMarginImage>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>                
                <SectionBody size="regular">
                  <h2 style={{ color: "#1D4289" }}>Patient brochure and starter kit</h2><br/>
                  Simple language and a clean visual design make it easy for patients and caregivers to learn about PLUVICTO, how it works, and what to expect
during treatment. In addition, physicians were provided "starter kits" to help patients keep track of their treatment schedule, care team contacts,
questions about PLUVICTO, and any concerns they might want to discuss at their next visit.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin>
          <Container>
            <RevomeMarginImage>
              <Img fluid={data.image6.childImageSharp.fluid} />
            </RevomeMarginImage>
          </Container>
        </SectionContainer>

        <hr/>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>                
                <SectionBody size="regular">
                  <h2 style={{ color: "#1D4289" }}>Patient release flashcard</h2><br/>
                  Because PLUVICTO involves exposure to radiation, patients must follow important instructions when they go home. This flashcard allows the
nurse to walk through those instructions with the patient and/or caregiver and send it home with them for reference.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container>
            <RevomeMarginImage>
              <Img fluid={data.image7.childImageSharp.fluid} />
            </RevomeMarginImage>
          </Container>
        </SectionContainer>

         

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingTop: "60px", paddingBottom: "60px", backgroundColor: "#ffffff", color: "#5C5C5C" }}>
            <Row>
              <Col md={8}>
                <AccentHeader>                  
                  <h2 style={{ color: "#1D4289" }}>The Results</h2>
                </AccentHeader>
                <SectionBody size="regular">
                  At launch, the PLUVICTO sales force was armed with 500 patient starter kits for physicians' offices. The print campaign not only supported
the digital patient campaign, but also provided a tangible means for patients, caregivers, and the care team to maintain ongoing
conversations throughout the duration of treatment.
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>        

        <SectionContainer  noTopMargin noBottomMargin>
          <Container>
            <Row>
              <Col md={8}>
                <hr/>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>

        <SectionContainer noTopMargin noBottomMargin>
          <Container style={{ paddingBottom: "60px" }}>
            <Row>
              <Col md={8}>                
                <SectionBody size="small">
                  mCRPC, metastatic castration-resistant prostate cancer; PET, positron emission tomography; PSA, prostate-specific antigen; PSMA, prostatespecific
membrane antigen; PSMA+, PSMA positive
                </SectionBody>
              </Col>              
            </Row>
          </Container>
        </SectionContainer>


      </>
    </ThemeProvider>
  </Layout>
)

export default pluvictoConsumerPrint

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "2023/pluvicto1/image1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    image2: file(relativePath: { eq: "2023/pluvicto1/image2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image3: file(relativePath: { eq: "2023/pluvicto1/image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image4: file(relativePath: { eq: "2023/pluvicto1/image4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image5: file(relativePath: { eq: "2023/pluvicto1/image5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image6: file(relativePath: { eq: "2023/pluvicto1/image6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    image7: file(relativePath: { eq: "2023/pluvicto1/image7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
